/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import QuranChapter from "../templates/QuranChapterTemplate";
import { graphql } from "gatsby";

function Index(props: unknown): JSX.Element {
  return (
    <Fragment>
      {/* @ts-ignore */}
      <QuranChapter data={props.data} />
    </Fragment>
  );
}

Index.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};

export default Index;

export const quranQuery = graphql`
  query QuranInfoJson($slug: Int = 1) {
    allTranslationJson(filter: { chapterNumber: { eq: $slug } }) {
      nodes {
        verses {
          verseNumber
          verseString
        }
        nameEnglish
        nameTrans
        nameArabic
        chapterNumber
      }
    }
    allQuranInfoJson {
      nodes {
        nVerses
        nameEnglish
        nameTrans
        nameArabic
      }
    }
  }
`;
